
























import Vue from "vue";
import { Component } from "nuxt-property-decorator";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  name: "PaymentCancelModal",
})
export default class PaymentCancelModal extends Vue {
  get faTimes() {
    return faTimes;
  }

  onClose() {
    if (process.browser && window) {
      window.location.hash = "";
      this.$emit("close");
    }
  }
}
